export default {
	global: {
		skipToContent: "Saltar al contenido",
		viewAll: "Ver todo",
		viewMore: "Ver más",
		and: "y",
		readMore: "Leer más",
	},
	a11y: {
		play: "Reproducir vídeo",
		playIcon: "Icono de reproducción",
		closeModal: "Cerrar ventana modal",
		closeIcon: "Icono de cerrar",
		homeLink: "Enlace a la página principal",
		openMenu: "Abrir menú",
		menuIcon: "Icono de menú",
		contentHide: "Ocultar contenido",
		contentShow: "Mostrar contenido",
		goTo: "Ir a",
		readMore: "Lee más sobre",
		prev: "Anterior",
		next: "Siguiente",
	},
	Footer: {
		newsletterTitle: "Newsletter",
		connect: "Conecta con ECIJA",
		facebook: "Facebook",
		flicker: "Flickr",
		instagram: "Instagram",
		linkedIn: "LinkedIn",
		newsletter: "Newsletter",
		podcast: "Podcast",
		snapchat: "Snapchat",
		tiktok: "TikTok",
		twitter: "X",
		youtube: "YouTube",
	},
	OfficesDistributor: {
		goTo: "Ir a página de sede",
	},
	SectorsAndPracticesDistributor: {
		moreInfo: "Más información",
		PRACTICE_AREA: "Áreas de práctica",
		PRACTICE_AREA_mob: "Áreas",
		SECTOR: "Sectores",
		SECTOR_mob: "Sectores",
	},
	ShareButton: {
		share: "Share",
	},
	PracticeAreaDetail: {
		areas: "Áreas de práctica",
	},
	Quote: {
		Linkedin: "Ir a LinkedIn",
		Facebook: "Ir a Facebook",
		Twitter: "Ir a X",
		Instagram: "Ir a Instagram",
		Youtube: "Ir a YouTube",
		Email: "Enviar correo electrónico",
		TikTok: "Ir a TikTok",
	},
	PeopleDetail: {
		contact: "Contacto",
		services: "Servicios relacionados",
		insights: "Insights relacionados",
	},
	SectorDetail: {
		sectors: "Sectores",
	},
	PeopleDistributor: {
		goTo: "Ver ficha de profesional",
		services: "Ver profesionales por área",
		search: "Buscar por nombre",
		areas: "Áreas de práctica",
		sectors: "Sectores",
	},
	Lists: {
		loading: "Sólo unos segundos, estamos cargando los resultados",
		results: "resultados encontrados",
		searchLabel: "Buscar...",
		search: "Buscar",
		selected: "Filtros seleccionados",
		reset: "Borrar filtros",
	},
	PeopleList: {
		areas: "Áreas de práctica",
		sectors: "Sectores",
		services: "Servicios",
		position: "Cargos",
		offices: "Sedes",
		search: "Buscar profesionales por su nombre...",
	},
	SectorPracticeAreaList: {
		exploreAreas: "Explora nuestras",
		areas: "áreas de práctica",
		exploreSectors: "Explora nuestros",
		sectors: "sectores",
		search: "Buscar un servicio",
		allSearch: "Ver todo",
		allSearch_tab: "Ver todo",
		allSearch_mob: "Todos los servicios",
		areasSearch: "Áreas de práctica",
		areasSearch_tab: "Á. de práctica",
		areasSearch_mob: "Áreas de práctica",
		sectorsSearch: "Sectores",
		sectorsSearch_tab: "Sectores",
		sectorsSearch_mob: "Sectores",
		services: "Todos los servicios",
	},
	InsightsList: {
		category: "Tipo de contenido",
		areas: "Área de práctica",
		sectors: "Sector",
		offices: "Sedes",
		position: "Socios",
		search: "Busca insights...",
	},
	InsightsDistributor: {
		insights: "Tipo de insights",
	},
	InsightsDetail: {
		download: "Descargar en",
		relatedPartners: "Socios _relacionados_",
		latest: "ACTUALIDAD _#ECIJA_",
	},
	OfficesMap: {
		region: "Seleccionar Región",
		viewAll: "Ver todo",
		viewAllMob: "Seleccionar Región",
		goToOffice: "Ir a página de sede",
		other: "Otros",
	},
	OfficesList: {
		layoutToggle: "Vista de mapa",
		areas: "Áreas de práctica",
		sectors: "Sectores",
		search: "Buscar una sede",
		officesIn: "Sedes en",
		moreOfficesIn: "Más sedes en",
		other: "Otros",
	},
	CareerDetail: {
		vacancies: "Vacantes",
		requirements: "_Requisitos_ del perfil",
		info: "¿Qué significa formar parte de _#ecijateam?_",
		valueContribution: "¿Qué te _podemos aportar?_",
	},
	CareersDistributor: {
		profile: "Perfil",
		apply: "Aplicar a oferta",
	},
	CareersList: {
		layoutToggle: "Vista de listado",
		areas: "Áreas de práctica",
		sectors: "Sectores",
		position: "Puesto",
		offices: "Localización",
		search: "Busca por puesto, categoría, sede...",
		view: "Ver detalles de la oferta",
	},
	AwardsDetail: {
		insights: "Insights relacionados",
		services: "Servicios relacionados",
	},
	SearchResults: {
		intro: {
			titleSearch: "Has buscado ",
			resultsHeading: "resultados en total",
		},
		filters: {
			ALL: "TODOS",
			PEOPLE: "PERSONAS",
			CAPABILITIES: "CAPACIDADES",
			INSIGHTS: "IDEAS",
			SEDES: "OFICINAS",
			CAREERS: "TRABAJOS",
			OTHERS: "OTROS",
		},
		segments: {
			AWARDS: "Premios",
			CAREER: "Carrera",
			EVENT: "Evento",
			INSIGHTS: "Perspectivas",
			PEOPLE: "Personas",
			PRACTICE_AREA: "Área de práctica",
			SECTOR: "Servicios",
		},
	},
	InputSearch: {
		inputPlaceholder: "Escribe aquí para buscar de nuevo...",
		search: "Buscar",
	},
};
