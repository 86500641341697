export default {
	global: {
		skipToContent: "Skip to content",
		viewAll: "View all",
		viewMore: "View more",
		and: "and",
		readMore: "Read more",
	},
	a11y: {
		play: "Play video",
		playIcon: "Play icon",
		closeModal: "Close modal",
		closeIcon: "Close icon",
		homeLink: "Link to homepage",
		openMenu: "Open menu",
		menuIcon: "Menu icon",
		contentHide: "Hide content",
		contentShow: "Show content",
		goTo: "Go to",
		readMore: "Read more about",
		prev: "Previous",
		next: "Next",
	},
	Footer: {
		newsletterTitle: "Newsletter",
		connect: "Connect with ECIJA",
		facebook: "Facebook",
		flicker: "Flickr",
		instagram: "Instagram",
		linkedIn: "LinkedIn",
		newsletter: "Newsletter",
		podcast: "Podcast",
		snapchat: "Snapchat",
		tiktok: "TikTok",
		twitter: "X",
		youtube: "YouTube",
	},
	OfficesDistributor: {
		goTo: "Go to office page",
	},
	SectorsAndPracticesDistributor: {
		moreInfo: "More information",
		PRACTICE_AREA: "Practice areas",
		PRACTICE_AREA_mob: "Areas",
		SECTOR: "Sectors",
		SECTOR_mob: "Sectors",
	},
	ShareButton: {
		share: "Share",
	},
	PracticeAreaDetail: {
		areas: "Practice areas",
	},
	Quote: {
		Linkedin: "Go to LinkedIn",
		Facebook: "Go to Facebook",
		Twitter: "Go to X",
		Instagram: "Go to Instagram",
		Youtube: "Go to YouTube",
		Email: "Send e-mail",
		TikTok: "Go to TikTok",
	},
	PeopleDetail: {
		contact: "Contact",
		services: "Related services",
		insights: "Related insights",
	},
	SectorDetail: {
		sectors: "Sectors",
	},
	PeopleDistributor: {
		goTo: "View profile",
		services: "View professionals by area",
		search: "Search",
		areas: "Practice areas",
		sectors: "Sectors",
	},
	Lists: {
		loading: "Just a few seconds, we are loading the results",
		results: "results found",
		searchLabel: "Search...",
		search: "Search",
		selected: "Selected filters",
		reset: "Delete filters",
	},
	PeopleList: {
		areas: "Practice areas",
		sectors: "Sectors",
		services: "Services",
		position: "Positions",
		offices: "Offices",
		search: "Search for professionals by name...",
	},
	SectorPracticeAreaList: {
		exploreAreas: "Explore our",
		areas: "practice areas",
		exploreSectors: "Explore our",
		sectors: "sectors",
		search: "Search for a service",
		allSearch: "View all",
		allSearch_tab: "View all",
		allSearch_mob: "All services",
		areasSearch: "Practice areas",
		areasSearch_tab: "P. areas",
		areasSearch_mob: "Practice areas",
		sectorsSearch: "Sectors",
		sectorsSearch_tab: "Sectors",
		sectorsSearch_mob: "Sectors",
		services: "All services",
	},
	InsightsList: {
		category: "Content type",
		areas: "Practice area",
		sectors: "Sector",
		offices: "Offices",
		position: "Partners",
		search: "Search for insights...",
	},
	InsightsDistributor: {
		insights: "Insight categories",
	},
	InsightsDetail: {
		download: "Download in",
		relatedPartners: "_Related_ partners",
		latest: "LATEST FROM _#ECIJA_",
	},
	OfficesMap: {
		region: "Select Region",
		viewAll: "View all",
		viewAllMob: "Select Region",
		goToOffice: "Go to office page",
		other: "Other",
	},
	OfficesList: {
		layoutToggle: "Map view",
		areas: "Practice areas",
		sectors: "Sectors",
		search: "Search for offices",
		officesIn: "Offices in",
		moreOfficesIn: "More offices in",
		other: "Other",
	},
	CareerDetail: {
		vacancies: "Job openings",
		requirements: "Profile _requirements_",
		info: "What does it mean to be part of _#ecijateam?_",
		valueContribution: "What can we _offer you?_",
	},
	CareersDistributor: {
		profile: "Job profile",
		apply: "Apply for this position",
	},
	CareersList: {
		layoutToggle: "List view",
		areas: "Practice areas",
		sectors: "Sectors",
		position: "Positions",
		offices: "Location",
		search: "Search for position, category, office...",
		view: "View position details",
	},
	AwardsDetail: {
		insights: "Related insights",
		services: "Related services",
	},
	SearchResults: {
		intro: {
			titleSearch: "Pending translations",
			resultsHeading: "Pending translations",
		},
		filters: {
			ALL: "ALL",
			PEOPLE: "PEOPLE",
			CAPABILITIES: "CAPABILITIES",
			INSIGHTS: "INSIGHTS",
			SEDES: "OFFICES",
			CAREERS: "CAREERS",
			OTHERS: "OTHERS",
		},
		segments: {
			AWARDS: "Awards",
			CAREER: "Career",
			EVENT: "Event",
			INSIGHTS: "Insights",
			PEOPLE: "People",
			PRACTICE_AREA: "Practice Area",
			SECTOR: "Services",
		},
	},
	InputSearch: {
		inputPlaceholder: "Pending translations",
		search: "Pending translations",
	},
};
