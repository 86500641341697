import { SearchResultsProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { WhiteList } from "@schemas/modules";
import { heading, content } from "@schemas/presets";

const schema: Schema.Template<SearchResultsProps> = {
	schemaType: "template",
	displayName: "Search Results",
	component: "SearchResults",
	type: { label: "Static", value: "static" },

	content: [
		{
			...heading,
		},
		{
			...content,
			key: "subTitle",
			mandatory: true,
			hideable: false,
		},
		{
			type: "Wysiwyg",
			full: false,
			title: "No Results text",
			key: "noResults",
			mandatory: true,
			humanReadable: true,
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			whiteList: [...WhiteList],
			contentType: "modules",
			key: "relatedContent",
		},
	],

	default: {
		type: "template",
		templateType: "SearchResults",
		noResults: "Sorry, no results were found. Please try a different search.",
		title: {
			content: "Lorem ipsum",
			tag: "h1",
		},
		subtitle: "Lorem ipsum",
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/SearchResults/thumbnail@1x.png",
		"2x": "/thumbnails/templates/SearchResults/thumbnail@2x.png",
	},
};

export default schema;
