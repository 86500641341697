//
// Griddo Templates
//
// Every Template must be imported and reexported.
// Yes, a barrel index :)

import * as React from "react";

// Templates
const AwardsDetail = React.lazy(() => import("./AwardsDetail"));
const BasicTemplate = React.lazy(() => import("./BasicTemplate"));
const CareerDetail = React.lazy(() => import("./CareerDetail"));
const CareersList = React.lazy(() => import("./CareersList"));
const Error404 = React.lazy(() => import("./Error404"));
const EventDetail = React.lazy(() => import("./EventDetail"));
const InsightsDetail = React.lazy(() => import("./InsightsDetail"));
const InsightsList = React.lazy(() => import("./InsightsList"));
const OfficesList = React.lazy(() => import("./OfficesList"));
const PeopleDetail = React.lazy(() => import("./PeopleDetail"));
const PeopleList = React.lazy(() => import("./PeopleList"));
const PracticeAreaDetail = React.lazy(() => import("./PracticeAreaDetail"));
const SectorDetail = React.lazy(() => import("./SectorDetail"));
const SectorPracticeAreaList = React.lazy(
	() => import("./SectorPracticeAreaList"),
);
const SearchResults = React.lazy(() => import("./SearchResults"));

// Griddo bundle export
export default {
	AwardsDetail,
	BasicTemplate,
	CareerDetail,
	CareersList,
	Error404,
	EventDetail,
	InsightsDetail,
	InsightsList,
	OfficesList,
	PeopleDetail,
	PeopleList,
	PracticeAreaDetail,
	SectorDetail,
	SectorPracticeAreaList,
	SearchResults,
};
